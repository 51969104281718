<template>
  <div class="section container">
    <div>
      <header class="modal-card-head">
        <p class="card-header-title is-centered">{{ event.name }}</p>
      </header>
      <br>
      <div class="columns">
        <div class="column">
          <div class="card-content is-flex is-horizontal-center">
            <figure class="image" v-if="loaded">
              <img v-if="event.image_path" :src='imageURL(event.image_path)' alt="image of the event">
              <img v-else src="@/assets/logo-arene.png" alt="image of the event">
            </figure>
          </div>
        </div>
        <div class="column">
          <b-field label="TCG">
            {{ event.game.name }}
          </b-field>
          <b-field label="Prix">
            {{ Number(event.price).toFixed(2) }} CHF
          </b-field>
          <b-field label="Date">
            {{ event.showDate }}
          </b-field>
          <b-field v-if="event.nb_place>=1" label="Places disponibles">
            {{ event.available_places }}
          </b-field>
          <b-field v-else label="Plus aucune place disponible">
          </b-field>
          <div v-if="eventPassed(event)" >
            <a v-if="event.result_path" class="button has-background-success has-text-white"
               :href="imageURL(event.result_path)" target="_blank">Afficher les résultats</a>
            <b-button v-else type="is-success">Résultats non disponibles</b-button>
          </div>
          <div v-else>
            <b-button v-if="event.available_places <= 0" type="is-success">Aucune place disponible</b-button>
            <b-button v-else-if="!participe" type="is-success" @click="subscribe(event)">S'inscrire</b-button>
            <b-button v-else-if="participe" type="is-success">Déjà inscrit</b-button>
          </div>
          <br>
        </div>
      </div>
    </div>
    <div>
      <div class="card" v-if="event.description">
        <div class="card-content">
          <strong class="card-header-title is-centered">Description</strong>
          <b-field v-html="event.description" label="Description" style="white-space: pre-line"></b-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {DateTime} from 'luxon'
import {imageMixin} from '@/mixins/image'
import staticFileMixin from '@/mixins/static-file-mixin'
import notification from "@/mixins/notification";

export default {
  data: () => ({
    event: {},
    idEvent: null,
    participe: false,
    showResults: false,
    loaded: false,
  }),
  mixins: [imageMixin, staticFileMixin, notification],
    computed: {
      isLoggedIn: function () {
        return this.$store.getters.isLoggedIn
      },
  },
  methods: {
    eventPassed(event) {
      const today = DateTime.now()
      if (today.hasSame(event.realDate, 'day')) {
        return true
      }
      if (event.realDate < today) {
        return true
      }
      if (event.realDate > today) {
        return false
      }
    },
    subscribe() {
      this.$store.commit('changeTmpEvent', true)
      this.$store.commit('EVENT_OK', true)
      this.$store.commit('selectedEvent', this.event)
      this.$router.push({name: 'ValidationEvent'})
    }
  },
  async mounted() {
    this.idEvent = this.$route.params.id
    if (this.isLoggedIn) {
      this.$store.dispatch('getUser')
        .then(() => {
          this.$store.dispatch('isParticipant', this.idEvent)
            .then(data => {
              this.participe = data.is_participant
            })
        })
        .catch(() => {
          this.showError('Impossible de charger le compte.')
          this.$store.dispatch('logout')
        });
    }
    await this.$store.dispatch('index', {path: 'events/', data: this.idEvent})
      .then(data => {
        this.event = data.data
        const myEventBdd = {}
        myEventBdd.completeDate = new Date(data.data.date)
        const myYear = myEventBdd.completeDate.getFullYear()
        const myMonth = myEventBdd.completeDate.getMonth() + 1
        const myDay = myEventBdd.completeDate.getDate()
        const myHour = myEventBdd.completeDate.getHours()
        const myMinute = myEventBdd.completeDate.getMinutes()
        myEventBdd.date = DateTime.local(myYear, myMonth, myDay)
        this.event.realDate = myEventBdd.date
        this.event.showDate = new Date(this.event.realDate).toLocaleDateString() + ' à ' + myHour + 'h' + myMinute
      }).catch(() => {
        this.showError('Impossible de charger l\'événement.')
      });
    this.loaded = true
  }
}
</script>
